import backend from '../apiUtils/backend'
import { ethPsuedoAddress } from '../helpers/constants'
import { Token } from '../interfaces/interfaces'
import { GetContractTypeEnum, getTokenContract } from './getContract'

const fillTokenDetails = async (token: Token): Promise<Token> => {
	if (token.name && token.symbol) return token

	if (token.address === ethPsuedoAddress) {
		return {
			...token,
			name: 'Eth',
			symbol: 'ETH',
		}
	}

	const contract = getTokenContract(token.address, {
		type: GetContractTypeEnum.ReadOnly,
		chainId: Number(token.network_chain_id),
	})

	const changedProps: { property: string; value: string }[] = []

	if (!token.name) {
		try {
			token.name = await contract.name()
			changedProps.push({ property: 'name', value: token.name })
		} catch (error) {
			console.log(`No name found for token: ${token.address}`)
		}
	}

	if (!token.symbol) {
		try {
			token.symbol = await contract.symbol()
			changedProps.push({ property: 'symbol', value: token.symbol })
		} catch (error) {
			console.log(`No symbol found for token: ${token.address}`)
		}
	}

	// save to db if anything was fetched here
	if (changedProps.length || window == undefined) {
		const body = changedProps.reduce((body, cur) => ({ ...body, [cur.property]: cur.value }), {})
		try {
			await backend.put(
				`/token?chain=${token.network_chain_id}`,
				{ ...body, address: token.address },
				{
					headers: { 'x-api-key': process.env.ADMIN_API_KEY },
				}
			)
		} catch (error) {
			console.error(error)
		}
	}

	return token
}

export default fillTokenDetails
