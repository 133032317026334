import { useQuery, UseQueryResult } from 'react-query'
import { getCurrentUserProfile } from '../helpers/queryFunctions'
import { FollowProfile, User } from '../interfaces/interfaces'
import useCurrentUser from './useCurrentUser'

/**
 * @returns Profile of logged in user
 */
const useCurrentProfile = (): UseQueryResult<{
	user: User
	followings: FollowProfile[]
}> => {
	const { data: user } = useCurrentUser()

	return useQuery('current_profile', getCurrentUserProfile, {
		retry: false,
		staleTime: 600000,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: Boolean(user),
	})
}

export default useCurrentProfile
