import { Collapse } from '@chakra-ui/react'
import { useAppContext } from '@components/app-context'
import { createToast } from '@components/Toasts'
import useCurrentUser from '@hooks/useCurrentUser'
import useSigninWithWallet from '@hooks/useSigninWithWallet'
import { WalletType } from 'lib/wallet'
import Image from 'next/image'
import QRCode from 'qrcode'
import { FC, useEffect, useRef } from 'react'
import { ConnectHeader, ConnectWalletStepHeading, Navigation, Retry } from '../ConnectComponents'

const WalletConnect: FC<{
	goBack: () => void
}> = ({ goBack }) => {
	const canvasRef = useRef<HTMLCanvasElement>()
	const { walletconnectURI, walletProvider } = useAppContext()
	const { data: user } = useCurrentUser()

	const { retry } = useSigninWithWallet({ wallet: WalletType.WalletConnect })

	useEffect(() => {
		if (canvasRef.current && walletconnectURI)
			QRCode.toCanvas(canvasRef.current, walletconnectURI, { scale: 6 }).catch(() =>
				createToast({ body: 'Error in creating the QR code' })
			)
	}, [walletconnectURI])

	return (
		<>
			<ConnectHeader />
			{/* <div className="border border-color-4 divide-y divide-color-4 w-full mt-8 rounded-2xl overflow-y-auto"> */}
			<div className="border border-color-4 divide-y divide-color-4 w-full mt-8 rounded-2xl">
				<div className="flex justify-between items-center p-4 bg-color-2 rounded-t-2xl w-full">
					<h1 className="font-semibold text-color-8 text-lg">WalletConnect</h1>
					<Image src="/images/Wallets/wallet-connect.svg" height={19} width={19} />
				</div>
				{/* <ConnectWalletStepHeading step="authorize" onClick={toggleAuthorise} /> */}
				<ConnectWalletStepHeading
					step="authorize"
					isLoading={!walletProvider && !walletconnectURI}
					isSuccess={Boolean(walletProvider)}
				/>
				{/* --- QR Code --- */}
				<Collapse in={!walletProvider}>
					<div className="flex flex-col items-center w-full p-4">
						<h1 className="font-normal text-color-8 text-lg text-center">
							Scan QR code with a walletConnect compatible wallet
						</h1>
						<div className="flex justify-center my-4">
							<canvas width="350px" height="350px" className="rounded-md" ref={canvasRef} />
						</div>
					</div>
				</Collapse>
				<ConnectWalletStepHeading
					step="sign"
					message="Sign the message"
					isLoading={walletProvider && !user}
					isSuccess={Boolean(user)}
				/>
			</div>
			<div className="flex items-center w-full justify-between pt-8 px-4">
				<Navigation type="back" customName="Back to wallet list" onClick={goBack} />
				<Retry onClick={retry} />
			</div>
		</>
	)
}

export default WalletConnect
