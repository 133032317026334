import { FC } from 'react'
import { User, FollowProfile } from '../../../interfaces/interfaces'
import { Avatar } from '@components/core'

interface UserCirclesProps {
	users: User[] | FollowProfile[]
	size?: 'sm' | 'md' | 'lg' | 'xl'
	clickable?: boolean
	className?: string
}

export const UserCircles: FC<UserCirclesProps> = ({ users, size = 'md', clickable = true, className }) => {
	return (
		<div className={`flex flex-wrap justify-center relative items-center ${className}`}>
			{users.map((user) => (
				<Avatar
					key={user.id}
					profilePicUrl={user.profile_pic_url}
					gradient1={user.gradient_1}
					gradient2={user.gradient_2}
					href={clickable && (user.user_name ?? user.wallet_address)}
					size={size}
					className="first:ml-0 -ml-1.5"
				/>
			))}
		</div>
	)
}
