import clsx from 'clsx'
import { ErrorMessage, Field, FieldAttributes, FormikErrors, FormikTouched } from 'formik'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import Link from 'next/link'
import { FC, ReactText, SetStateAction, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { tokenAgreementLink } from '../../helpers/constants'
import { parseAddressesEns, parseAddressesWithoutAmounts, ParseAddressRes } from '../../helpers/utils'
import useCurrentProfile from '../../hooks/useCurrentProfile'
import { updateToast } from '../Toasts'
import DaysDropdown from './DaysDropdown'

interface FieldProps extends FieldAttributes<any> {
	title: string
	description?: string
	placeholder?: string
	disabled?: boolean
}

export const FormField: FC<FieldProps> = ({ name, description, className, title, disabled = false, ...rest }) => {
	const { data: userProfile } = useCurrentProfile()

	return (
		<div className={clsx(className, 'mt-6')}>
			<h1 className="font-medium text-base capitalize pl-4 pb-2 dark:text-dark-8">{title}</h1>
			<Field
				name={name}
				{...rest}
				className={clsx(
					'w-full dark:border-dark-3 card-bg font-medium text-sm py-4 px-4 rounded-xl border border-[#BDBDBD] outline-none text-[#828282]',
					rest.component === 'textarea' && 'h-40',
					(!userProfile || disabled) && 'cursor-not-allowed'
				)}
				disabled={!userProfile || disabled}
			/>
			<ErrorMessage
				name={name}
				component="p"
				className=" dark:border-dark-3 dark:bg-transparent font-medium text-xxs text-[#ee6243] ml-4 mt-2"
			/>
			{description && (
				<p className="font-medium text-sm text-deepgray dark:text-dark-6 ml-4 capitalize mt-2">{description}</p>
			)}
		</div>
	)
}

interface FieldWithYearsProps extends FieldProps {
	setDuration: (state: 'Months' | 'Years') => void
	showDropdown: boolean
	setShowDropdown: (value: SetStateAction<boolean>) => void
	duration: 'Months' | 'Years'
	disabled?: boolean
}

export const FormFieldWithYears: FC<FieldWithYearsProps> = ({
	name,
	description,
	className,
	setDuration,
	showDropdown,
	setShowDropdown,
	duration,
	title,
	disabled = false,
	...rest
}) => {
	const { theme } = useTheme()
	const { data: userProfile } = useCurrentProfile()

	return (
		<div className={clsx(className, 'mt-6')}>
			<h1 className="font-semibold text-base capitalize pl-4 pb-2 dark:text-dark-8">{title}</h1>
			<div className="w-full dark:border-dark-3 card-bg py-4 px-4 rounded-xl border border-[#BDBDBD] flex item-center justify-between">
				<Field
					name={name}
					{...rest}
					className={clsx(
						'font-medium text-sm outline-none text-[#828282] card-bg w-1/2',
						(!userProfile || disabled) && 'cursor-not-allowed'
					)}
					disabled={!userProfile || disabled}
				/>
				<div
					className={clsx('flex items-center relative cursor-pointer', { 'cursor-not-allowed': disabled })}
					onClick={() => {
						if (disabled) return
						setShowDropdown((prev) => !prev)
					}}
				>
					<h1
						className={` font-medium text-sm mr-2 ${
							duration ? 'text-black dark:text-dark-8' : 'text-deepgray dark:text-dark-8'
						}`}
					>
						{duration}
					</h1>
					{theme === 'light' ? (
						duration ? (
							<Image src="/images/Forms/down-arrow-black.svg" height={20} width={20} />
						) : (
							<Image src="/images/Forms/down-arrow.svg" height={20} width={20} />
						)
					) : (
						<Image src="/images/Forms/down-arrow-dark.svg" height={20} width={20} />
					)}
					<DaysDropdown setDuration={setDuration} isVisible={showDropdown} />
				</div>
			</div>

			<ErrorMessage
				name={name}
				component="p"
				className=" dark:border-dark-3 dark:bg-transparent font-medium text-xxs text-[#ee6243] ml-4 mt-2"
			/>
			{description && (
				<p className=" font-medium text-xxs text-deepgray dark:text-dark-6 ml-4 capitalize mt-2">{description}</p>
			)}
		</div>
	)
}

interface Consent {
	consent: boolean
}

interface ConsentProps<T extends Consent> {
	values: T
	touched: FormikTouched<T>
	errors: FormikErrors<T>
	disabled?: boolean
}

export const Consent = <T extends Consent>({ values, touched, errors, disabled }: ConsentProps<T>): JSX.Element => {
	const { data: userProfile } = useCurrentProfile()
	return (
		<>
			<label
				className={clsx(
					'flex w-full border rounded-xl dark:bg-darkCard p-4 justify-between items-center mt-8',
					values.consent ? 'border-[#4FBB6D]' : 'border-[#ee6243]',
					(!userProfile || disabled) && 'cursor-not-allowed'
				)}
			>
				<p className={` font-medium text-sm w-1/2 ${values.consent ? 'text-[#4FBB6D]' : 'text-[#ee6243]'}`}>
					I understand that these values can't be changed after deployment and I hereby accept the{' '}
					<Link href={tokenAgreementLink}>
						<a target="_blank">Token Agreement</a>
					</Link>
				</p>
				<Field type="checkbox" name="consent" className="h-4 w-4 rounded-xl" disabled={!userProfile || disabled} />
			</label>
			<p className=" font-medium text-xxs text-[#ee6243] ml-4 mt-2">{touched.consent && errors.consent}</p>
		</>
	)
}

interface FileInputProps {
	options: DropzoneOptions
	previewImg?: string
	title: string
	initialPic?: string
}

export const FileInput: FC<FileInputProps> = ({ options, previewImg, title, initialPic }) => {
	const [preview, setPreview] = useState('')
	const { data: userProfile } = useCurrentProfile()

	const { getInputProps, getRootProps } = useDropzone({
		...options,
		onDropAccepted: (files, e) => {
			options.onDropAccepted(files, e)
			if (files.length) {
				setPreview(URL.createObjectURL(files[0]))
			}
		},
	})

	return (
		<>
			<h1 className="w-full  font-semibold text-base capitalize pl-4 pb-2 dark:text-dark-8">{title}</h1>
			<div
				className={clsx(
					'dark:bg-darkCard dark:border-dark-3 py-10 px-4 fle rounded-xl hover:cursor-pointer mb-4',
					!userProfile && 'cursor-not-allowed'
				)}
				{...getRootProps()}
			>
				<input {...getInputProps()} disabled={!userProfile} className={clsx(!userProfile && 'cursor-not-allowed')} />
				{previewImg && (
					<div className={clsx('w-full flex justify-center mb-4', !userProfile && 'cursor-not-allowed')}>
						<img src={preview || initialPic || previewImg} />
					</div>
				)}
				<div className={clsx('flex flex-col items-center', !userProfile && 'cursor-not-allowed')}>
					<p className="dark:text-dark-8">Drag 'n' drop some files here, or click to select files</p>
					<em className="dark:text-dark-8">(Only *.jpeg and *.png images will be accepted)</em>
				</div>
			</div>
		</>
	)
}

type AddressPardingErrorType = 'duplicate' | 'invalid'

export class AddressParsingError {
	public addresses: string[]
	public type: AddressPardingErrorType

	constructor(_type: AddressPardingErrorType, _addresses: string[]) {
		this.addresses = _addresses
		this.type = _type
	}
}

export const parseAndCheckAddresses = async (
	input: string,
	toastId: ReactText,
	withAmounts = true,
	throwDuplicateError = true
): Promise<ParseAddressRes | undefined> => {
	let duplicateAddresses: string[], invalidAddresses: string[], addresses: string[], amounts: number[]

	if (withAmounts) {
		const res = await parseAddressesEns({ addressesWithAmount: input })
		duplicateAddresses = res.duplicateAddresses
		invalidAddresses = res.invalidAddresses
		addresses = res.data.inputAddresses
		amounts = res.data.inputAmounts
	} else {
		const res = await parseAddressesWithoutAmounts(input)
		duplicateAddresses = res.duplicateAddresses
		invalidAddresses = res.invalidAddresses
		addresses = res.addresses
	}

	if (invalidAddresses.length) {
		updateToast({
			toastId,
			body: (
				<>
					<p>
						{invalidAddresses.length} invalid address{invalidAddresses.length > 1 ? 'es' : ''} found
					</p>
					<div className="break-all">
						{invalidAddresses.map((addr) => (
							<p key={addr} className="my-2">
								{addr}
							</p>
						))}
					</div>
				</>
			),
			type: 'error',
		})
	} else if (duplicateAddresses.length) {
		if (throwDuplicateError) {
			toast.dismiss(toastId)
			updateToast({
				toastId,
				body: (
					<>
						<p>
							{duplicateAddresses.length} duplicate address{duplicateAddresses.length > 1 ? 'es' : ''} found
						</p>
						<div className="break-all">
							{duplicateAddresses.map((addr) => (
								<p key={addr} className="my-1">
									{addr}
								</p>
							))}
						</div>
					</>
				),
				type: 'error',
			})
		}
	}

	return { duplicateAddresses, invalidAddresses, data: { inputAddresses: addresses, inputAmounts: amounts ?? [] } }
}
