import copy from 'copy-to-clipboard'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { updateToast } from '../components/Toasts'

const copyToastId = 'copy-clipboard'

const useCopyClipboard = (showToast = false, timeout = 3000): [boolean, (toCopy: string) => void] => {
	const [isCopied, setIsCopied] = useState(false)

	const setCopy = useCallback((text) => {
		const didCopy = copy(text)
		setIsCopied(didCopy)
	}, [])

	useEffect(() => {
		if (isCopied) {
			if (showToast) {
				updateToast({ body: 'Copied to clipboard', toastId: copyToastId })
			}

			const hide = setTimeout(() => {
				setIsCopied(false)

				if (showToast) {
					toast.dismiss(copyToastId)
				}
			}, timeout)

			return () => {
				clearTimeout(hide)
			}
		}
		return undefined
	}, [isCopied, setIsCopied, timeout, showToast])

	return [isCopied, setCopy]
}

export default useCopyClipboard
