import { createToast } from '@components/Toasts'
import { Web3Provider } from '@ethersproject/providers'
import WalletConnectProvider from '@walletconnect/web3-provider'
import backend from 'apiUtils/backend'
import axios from 'axios'
import { ethers } from 'ethers'
import WalletLink, { WalletLinkProvider } from 'walletlink'
import { IProviderOptions } from 'web3modal'

type ValueOf<T> = T[keyof T]

// type OpenWalletConnectModal = (uri: string) => void

export const walletConnectOptions = (provider: WalletConnectProvider): ValueOf<IProviderOptions> => ({
	display: {
		logo: '/images/coinbase.svg',
		name: 'Coinbase',
		description: 'Use Coinbase Wallet app on mobile device',
	},
	options: {
		appName: 'Coinvise', // Your app name
		networkUrl: `https://mainnet.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77`,
		chainId: 1,
	},
	package: provider,
	connector: async (provider: WalletConnectProvider): Promise<WalletConnectProvider> => {
		await provider.enable()
		return provider
	},
})

interface GetWeb3ModalProviderOptions {
	walletconnect: {
		provider: WalletConnectProvider
	}
}

export enum WalletType {
	WalletConnect = 'custom-walletconnect',
	Metamask = 'injected',
	Coinbase = 'custom-walletlink',
}

type ProviderOptions = Record<WalletType, ValueOf<IProviderOptions>>

export const getWeb3ModalProviderOptions = ({ walletconnect }: GetWeb3ModalProviderOptions): ProviderOptions => ({
	injected: {
		display: {},
		package: null,
	},
	'custom-walletconnect': walletConnectOptions(walletconnect.provider),
	'custom-walletlink': {
		display: {
			logo: '/images/coinbase.svg',
			name: 'Coinbase',
			description: 'Use Coinbase Wallet app on mobile device',
		},
		options: {
			appName: 'Coinvise', // Your app name
			networkUrl: `https://mainnet.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77`,
			chainId: 1,
		},
		package: WalletLink,
		connector: async (_, options): Promise<WalletLinkProvider> => {
			const { appName, networkUrl, chainId } = options
			const walletLink = new WalletLink({
				appName,
			})
			const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
			await provider.enable()
			return provider
		},
	},
})

export const signinWithWallet = async (provider: Web3Provider): Promise<void> => {
	const signer = provider.getSigner()
	const address = await signer.getAddress()

	// get nonce
	const nonce = await backend.get(`/user/getnonce?publicAddress=${address}`).then((res) => res.data?.nonce as string)

	// sign transaction
	const rawMessage = process.env.NEXT_PUBLIC_SIGNING_MESSAGE_ADD_WALLET + nonce
	const messageBytes = ethers.utils.toUtf8Bytes(rawMessage)
	try {
		const signature = await signer.provider.send('personal_sign', [
			ethers.utils.hexlify(messageBytes),
			address.toLowerCase(),
		])
		// login
		await axios.post('/api/login/signature', {
			signature,
			address,
		})
	} catch (error) {
		createToast({ body: 'Failed to sign in with wallet' })
	}
}
