import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'

const dropVariant = {
	hidden: {
		opacity: 0,
		transition: {
			duration: 0.2,
		},
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.2,
		},
	},
}

interface Props {
	setDuration: (state: 'Months' | 'Years') => void
	isVisible: boolean
}

const DaysDropdown: FC<Props> = ({ setDuration, isVisible }) => {
	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					variants={dropVariant}
					initial="hidden"
					animate="visible"
					exit="hidden"
					className="w-24 rounded-xl bg-light-1 dark:bg-darkCard  absolute z-10 flex flex-col justify-between items-center overflow-hidden cursor-default right-0"
					style={{ top: '-15px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}
				>
					<div
						className="bg-light-1 dark:bg-darkCard  w-full 1/4 text-center  font-medium text-xs text-deepgray dark:text-dark-8 dark:hover:bg-dark-8 dark:hover:text-darkBlack    p-2 hover:bg-[#F5F5F5]  hover:text-black"
						onClick={() => setDuration('Months')}
					>
						Months
					</div>
					<div
						className="bg-light-1 dark:bg-darkCard  w-full 1/4 text-center  font-medium text-xs text-deepgray dark:text-dark-8 dark:hover:bg-dark-8 dark:hover:text-darkBlack    p-2 hover:bg-[#F5F5F5]  hover:text-black"
						onClick={() => setDuration('Years')}
					>
						Years
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default DaysDropdown
