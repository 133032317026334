import { FC, KeyboardEvent } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

interface CheckboxProps {
	isChecked: boolean
	toggleCheckbox: () => void
	size?: 'sm' | 'md'
}

export const Checkbox: FC<CheckboxProps> = ({ isChecked, toggleCheckbox, size = 'md' }) => {
	const isCheckedStyle = isChecked ? 'border-yes text-yes' : 'border-no text-no'
	const sizeStyle = size === 'sm' ? 'w-5 h-5' : 'w-6 h-6'

	// User can Tab to focus and press space to toggle collapsible
	const onKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
		if (e.key === ' ' || e.key === 'Enter') {
			e.preventDefault()
			toggleCheckbox()
		}
	}
	return (
		<span
			tabIndex={0}
			onKeyDown={onKeyDown}
			onClick={toggleCheckbox}
			className={clsx(isCheckedStyle, sizeStyle, 'rounded-md border bg-transparent flex justify-center')}
		>
			{isChecked && <Image src="/images/check.svg" width={11} height={11}></Image>}
		</span>
	)
}
