import { useAppContext } from '@components/app-context'
import { useAppSetters } from '@components/contexts/AppProvider'
import { useConnectWalletContext } from '@components/contexts/ConnectWalletProvider'
import { Modal } from '@components/core'
import { Web3Provider } from '@ethersproject/providers'
import useCurrentUser from '@hooks/useCurrentUser'
import clsx from 'clsx'
import { tokenAgreementLink } from 'helpers/constants'
import { signinWithWallet, WalletType } from 'lib/wallet'
import Image from 'next/image'
import { FC, MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { ConnectHeader } from './ConnectComponents'
import Metamask from './connectors/Metamask'
import WalletConnect from './connectors/WalletConnect'

const ChooseWallet: FC<{
	setWallet: (wallet: WalletType) => void
	onClose: () => void
}> = ({ setWallet, onClose }) => {
	const { web3Modal } = useAppContext()
	const { setWalletProvider, setChainId } = useAppSetters()
	const queryClient = useQueryClient()

	const wallets = useMemo(
		() => [
			{
				name: 'MetaMask',
				image: '/images/Wallets/metamask.svg',
				onClick: () => {
					setWallet(WalletType.Metamask)
				},
			},
			{
				name: 'WalletConnect',
				image: '/images/Wallets/wallet-connect.svg',
				onClick: () => {
					setWallet(WalletType.WalletConnect)
				},
			},
			{
				name: 'Coinbase Wallet',
				image: '/images/Wallets/coinbase-wallet.svg',
				onClick: async () => {
					onClose()
					try {
						const provider = await web3Modal.connectTo(WalletType.Coinbase)
						const web3Provider = new Web3Provider(provider)
						await signinWithWallet(web3Provider)

						setWalletProvider(web3Provider)
						setChainId(await web3Provider.getNetwork().then((network) => network.chainId))

						await queryClient.refetchQueries('current_user')
						await queryClient.refetchQueries('current_profile')
						await queryClient.refetchQueries('balance')
					} catch (error) {
						//
					}
				},
			},
		],
		[onClose, queryClient, setChainId, setWallet, setWalletProvider, web3Modal]
	)

	return (
		<div className="flex flex-col items-center w-full">
			<ConnectHeader />
			<div className="border border-color-4 divide-y divide-color-4 w-full mt-8 rounded-2xl">
				{wallets.map((wallet, i) => (
					<WalletOption name={wallet.name} image={wallet.image} onClick={wallet.onClick} key={i} />
				))}
			</div>
			<div className="border border-color-4 w-full mt-8 rounded-2xl p-4">
				<h1 className="font-normal text-base text-color-6">By connecting your wallet:</h1>
				<div className="flex items-center mt-2">
					<svg
						width="15"
						height="15"
						viewBox="0 0 15 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="stroke-color-4 mr-2 ml-1"
					>
						<path d="M4.45896 8.2668L6.52887 9.93818L9.68659 5.5957" strokeWidth="1.5" strokeLinecap="round" />
						<rect x="0.84375" y="1.53809" width="12.46" height="12.46" rx="2.14608" strokeWidth="1.5" />
					</svg>
					<h1 className="font-normal text-base text-color-6 tracking-[0.01em]">You agree to our Token Agreement</h1>
				</div>
				<div className="flex items-start mt-2">
					<span className="mr-2 ml-1 mt-1">
						<svg
							width="15"
							height="15"
							viewBox="0 0 15 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="stroke-color-4"
						>
							<path d="M4.45896 8.2668L6.52887 9.93818L9.68659 5.5957" strokeWidth="1.5" strokeLinecap="round" />
							<rect x="0.84375" y="1.53809" width="12.46" height="12.46" rx="2.14608" strokeWidth="1.5" />
						</svg>
					</span>
					<h1 className="font-normal text-base text-color-6 tracking-[0.01em] break-words">
						You agree to our use of cookies to identify your session (authentication cookies).
						<a className="text-gradient-1" href={tokenAgreementLink} target="_blank" rel="noopener noreferrer">
							{' '}
							Learn more.
						</a>
					</h1>
				</div>
			</div>
			<h1 className="font-normal text-base text-color-6 tracking-[0.01em] break-words mt-6 self-start ml-2">
				New to Ethereum?
				<a
					className="text-gradient-1 ml-1"
					href="https://ethereum.org/en/wallets/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn more about wallets
				</a>
			</h1>
		</div>
	)
}

/**
 * Modal to connect to a web3 wallet
 * TODO: take open/close from context?
 */
const ConnectWalletModal: FC = () => {
	const [choosenWallet, setChoosenWallet] = useState<WalletType | null>(null)
	const { isOpen, onClose, wallet } = useConnectWalletContext()

	useEffect(() => {
		if (wallet) setChoosenWallet(wallet)
	}, [wallet])

	const { data: user } = useCurrentUser()
	const { walletProvider } = useAppContext()

	const goBack = (): void => setChoosenWallet(null)

	useEffect(() => {
		if (walletProvider && user && isOpen) {
			setTimeout(() => {
				onClose()
				setChoosenWallet(null)
			}, 1000)
		}
	}, [user, onClose, isOpen, walletProvider])

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="md" scrollBehavior="outside">
				{!choosenWallet && <ChooseWallet setWallet={setChoosenWallet} onClose={onClose} />}
				{choosenWallet &&
					(() => {
						switch (choosenWallet) {
							case WalletType.Metamask:
								return <Metamask />

							case WalletType.WalletConnect:
								return <WalletConnect goBack={goBack} />

							case WalletType.Coinbase:
								return null
						}
					})()}
			</Modal>
		</>
	)
}

export default ConnectWalletModal

interface WalletOptionsProps {
	name: string
	image: string
	onClick?: MouseEventHandler
}

const WalletOption: FC<WalletOptionsProps> = ({ name, image, onClick }) => {
	const [hover, setHover] = useState(false)
	return (
		<div
			className="flex items-center justify-between p-4 cursor-pointer"
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={onClick}
		>
			<h1 className={clsx('font-semibold text-lg smooth-hover', hover ? 'text-color-8' : 'text-color-6')}>{name}</h1>
			<Image src={image} width={30} height={30} />
		</div>
	)
}
