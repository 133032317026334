import { LinkOutlined } from '@ant-design/icons'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import React, { FC, ReactNode, ReactText } from 'react'
import { toast, ToastOptions, TypeOptions as ToastTypes } from 'react-toastify'
import { getChainBlockExplorer } from '../../helpers/utils'
import { useAppContext } from '../app-context'

interface NotificationProps {
	title: string
	body: string | ReactNode
	hash?: string
}

const currentDate = (): string => {
	const date = new Date()
	return `${date.getHours()}:${date.getMinutes()}`
}

const Notification: FC<NotificationProps> = ({ title, body, hash }) => {
	const { chainId } = useAppContext()

	return (
		<div className="p-2 select-none bg-color-0">
			<p className=" text-xs  " style={{ color: '#c4c4c4' }}>
				{currentDate()}
			</p>
			<div className="my-2">
				<p className=" text-lg leading-none pb-2 text-color-8">{title}</p>
				<div className=" text-base font-normal leading-none whitespace-normal select-text text-color-8">
					{hash ? (
						<a
							target="_blank"
							href={`${getChainBlockExplorer(chainId)}tx/${hash}`}
							rel="noopener noreferrer"
							className="underline flex items-center text-color-8"
						>
							View Transaction
							<LinkOutlined className="ml-2" />
						</a>
					) : (
						body
					)}
				</div>
			</div>
		</div>
	)
}

const Close: FC<{ closeToast: any }> = ({ closeToast }) => {
	const { theme } = useTheme()
	return (
		<div onClick={closeToast}>
			{theme === 'light' ? (
				<Image src="/images/MobileNav/close.svg" height={10} width={10} />
			) : (
				<Image src="/images/MobileNav/close-dark.svg" height={10} width={10} />
			)}
		</div>
	)
}

interface CreateToastsProps {
	title?: string
	body?: string | ReactNode
	progress?: number // progress ranges from 0 - 1 : refer react-toastify docs
	type?: ToastTypes
	hash?: string
	autoClose?: number
}

export const createToast = ({
	title,
	body,
	progress = 0,
	type = toast.TYPE.DEFAULT,
	hash,
	autoClose,
}: CreateToastsProps): React.ReactText => {
	const options: ToastOptions = {
		position: 'top-left',
		hideProgressBar: false,
		closeOnClick: !hash,
		progress: progress,
		autoClose: autoClose ?? false,
		type,
		className: 'bg-color-0',
		closeButton: Close,
	}

	return toast(<Notification title={title} body={body} hash={hash} />, options)
}

interface UpdateToastsProps {
	title?: string
	body?: string | ReactNode
	progress?: number // progress ranges from 0 - 1 : refer react-toastify docs
	toastId?: string | number
	type?: ToastTypes
	hash?: string
	closeOnClick?: boolean
}

export const updateToast = ({
	toastId,
	progress = 0.01,
	title,
	body,
	type = toast.TYPE.DEFAULT,
	hash,
	closeOnClick = true,
}: UpdateToastsProps): void | ReactText => {
	const options: ToastOptions = {
		position: 'top-left',
		closeOnClick: hash ? false : closeOnClick,
		progress: progress === 1 ? 0.99 : progress,
		draggable: false,
		autoClose: false,
		type,
		className: 'bg-color-0',
		closeButton: Close,
	}

	const render = <Notification title={title} body={body} hash={hash} />

	if (!toastId) {
		return toast(render, {
			...options,
			type,
		})
	}

	if (!toast.isActive(toastId)) {
		setTimeout(() => {
			if (!toast.isActive(toastId)) {
				toast(render, {
					...options,
					type,
					toastId,
				})
			}
			toast.update(toastId, {
				...options,
				render,
			})
		}, 500)
	}

	toast.update(toastId, {
		...options,
		render,
	})

	return
}
