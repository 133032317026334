import { useAppContext } from '@components/app-context'
import { useAppSetters } from '@components/contexts/AppProvider'
import { createToast } from '@components/Toasts'
import { Web3Provider } from '@ethersproject/providers'
import backend from 'apiUtils/backend'
import axios from 'axios'
import { ethers } from 'ethers'
import { WalletType } from 'lib/wallet'
import { useCallback, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useCurrentUser from './useCurrentUser'

type Options = {
	enabled?: boolean
	wallet: WalletType
}

type Return = {
	signIn: () => Promise<void>
	retry: () => Promise<void>
}

const useSigninWithWallet = ({ enabled = true, wallet }: Options): Return => {
	const { walletProvider } = useAppContext()
	const { setWalletProvider, setChainId } = useAppSetters()

	const cancelRef = useRef<boolean>(false)
	const { data: user } = useCurrentUser()
	const queryClient = useQueryClient()
	const { web3Modal } = useAppContext()

	const connectWallet = useCallback(async (): Promise<void> => {
		if (!web3Modal) return
		const provider = await web3Modal.connectTo(wallet)

		const web3Provider = new Web3Provider(provider)
		setWalletProvider(web3Provider)
		setChainId(await web3Provider.getNetwork().then((network) => network.chainId))
	}, [setChainId, setWalletProvider, wallet, web3Modal])

	const signIn = useCallback(async (): Promise<void> => {
		if (!walletProvider?.getSigner?.()) return

		const address = await walletProvider.getSigner().getAddress()

		// get nonce
		const nonce = await backend.get(`/user/getnonce?publicAddress=${address}`).then((res) => res.data?.nonce as string)

		// sign transaction
		const rawMessage = process.env.NEXT_PUBLIC_SIGNING_MESSAGE_ADD_WALLET + nonce
		const messageBytes = ethers.utils.toUtf8Bytes(rawMessage)

		try {
			const signature = await walletProvider.send('personal_sign', [
				ethers.utils.hexlify(messageBytes),
				address.toLowerCase(),
			])
			// login
			await axios.post('/api/login/signature', {
				signature,
				address,
			})

			await queryClient.refetchQueries('current_user')
			await queryClient.refetchQueries('current_profile')
			await queryClient.refetchQueries('balance')
		} catch (error) {
			cancelRef.current = true
			createToast({ body: 'Failed to sign in with wallet', type: 'error' })
		}
	}, [queryClient, walletProvider])

	const retry = useCallback(async (): Promise<void> => {
		cancelRef.current = false
		toast.dismiss()

		await signIn()
	}, [signIn])

	useEffect(() => {
		if (!web3Modal || walletProvider || user) return

		connectWallet()
	}, [connectWallet, user, walletProvider, web3Modal])

	useEffect(() => {
		if (!enabled || cancelRef.current || !walletProvider || user) return

		signIn()
	}, [enabled, signIn, user, walletProvider])

	return {
		signIn,
		retry,
	}
}

export default useSigninWithWallet
