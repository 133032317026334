import { Button as AntButton } from 'antd'
import LoadingIcon from 'antd/lib/button/LoadingIcon'
import clsx from 'clsx'
import Link from 'next/link'
import { ButtonHTMLAttributes, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import styled from 'styled-components'
import { absoluteUrlRegex } from '../../helpers/constants'

// TODO: Make a custom button component with loading, disbaled etc
// Refer: https://github.com/ant-design/ant-design/blob/master/components/button/button.tsx

// interface Props extends AntButtonProps {
// 	bg?: string
// 	bgHover?: string
// }

// TODO: figure out proper types
const Button = styled<any>(AntButton).attrs((props) => ({
	className: clsx(props.className, 'py-2 text-md'),
}))`
	border-radius: 20px;
	background: ${(props) => props.bg ?? 'black'};
	color: ${(props) => props.color ?? 'white'};
	height: auto;
	line-height: 1.5 !important;
	width: ${(props) => (props.$fullWidth ? '100%' : 'auto')};
	white-space: normal;
	:hover,
	:active,
	:focus {
		background: ${(props) => props.bgHover ?? '#333'} !important;
		color: white !important;
	}
	:disabled {
		background: #dadbe1 !important;
		color: rgb(86, 90, 105) !important;
	}
`

export default Button

/*
export const GradientButton = styled<ButtonType>(AntButton as any).attrs((props) => ({
	className: clsx(
		props.className,
		'py-3 text-base  font-semibold',
		'text-white dark:text-dark-1',
		'hover:text-white active:text-white focus:text-white',
		'dark:hover:text-dark-1 dark:active:text-dark-1 dark:focus:text-dark-1',
		'disabled:bg-[#dadbe1] disabled:text-[#565a69] dark:disabled:bg-darkInactive dark:disabled:hover:bg-darkInactive dark:disabled:text-[#8988B0] dark:disabled:hover:text-[#8988B0]'
	),
}))`
	border-radius: 12px;
	height: auto;
	line-height: 1.5 !important;
	width: ${(props) => (props.$fullWidth ? '100%' : 'auto')};
	white-space: normal;
`
*/

interface CustomButtonProps extends Omit<ButtonHTMLAttributes<any>, 'className' | 'type'> {
	$fullWidth?: boolean
	className?: string
	href?: string
	loading?: boolean
	icon?: ReactNode
	htmlType?: 'button' | 'submit' | 'reset'
	gradientOff?: boolean
}

const GradientButtonWithoutRef: ForwardRefRenderFunction<any, CustomButtonProps> = (
	{ $fullWidth: fullWidth, className, children, disabled, loading, icon, href, htmlType, gradientOff, ...rest },
	ref
) => {
	const iconNode = loading ? (
		<span className="mr-2">
			<LoadingIcon loading prefixCls="custom" existIcon={false} />
		</span>
	) : (
		icon
	)

	const classes = clsx(
		{
			'disabled:bg-[#dadbe1] disabled:text-[#565a69] dark:disabled:bg-darkInactive dark:disabled:hover:bg-darkInactive dark:disabled:text-[#8988B0] dark:disabled:hover:text-[#8988B0] disabled:cursor-not-allowed disabled:bg-none':
				disabled,
		},
		className,
		{ 'w-full': fullWidth },
		'flex justify-center items-center',
		'py-3 text-lg  font-semibold',
		'hover:text-darkWhite active:text-white focus:text-white',
		'dark:hover:text-dark-6 dark:active:text-dark-1 dark:focus:text-dark-1',
		!gradientOff && 'text-white dark:text-dark-1'
	)

	if (href != undefined && !disabled) {
		if (absoluteUrlRegex.test(href)) {
			return (
				<a
					{...rest}
					href={href}
					ref={ref}
					className={classes}
					style={{
						borderRadius: '12px',
					}}
				>
					{iconNode}
					{children}
				</a>
			)
		}

		return (
			<Link href={href}>
				<a
					{...rest}
					ref={ref}
					className={classes}
					style={{
						borderRadius: '12px',
					}}
					type={htmlType}
				>
					{iconNode}
					{children}
				</a>
			</Link>
		)
	}

	return (
		<button
			type={htmlType || 'button'}
			disabled={disabled}
			style={{
				borderRadius: '12px',
			}}
			ref={ref}
			className={classes}
			{...rest}
		>
			{iconNode}
			{children}
		</button>
	)
}

export const GradientButton = forwardRef(GradientButtonWithoutRef)
