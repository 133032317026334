import { useAppContext } from '@components/app-context'
import useCurrentUser from '@hooks/useCurrentUser'
import useSigninWithWallet from '@hooks/useSigninWithWallet'
import { WalletType } from 'lib/wallet'
import { FC } from 'react'
import { ConnectHeader, ConnectWalletStepHeading, Retry } from '../ConnectComponents'

const Metamask: FC = () => {
	const { data: user } = useCurrentUser()

	const { walletProvider } = useAppContext()

	const { retry } = useSigninWithWallet({ wallet: WalletType.Metamask })

	return (
		<div className="flex flex-col items-center justify-center w-full">
			<ConnectHeader />
			<div className="border border-color-4 divide-y divide-color-4 w-full mt-8 rounded-2xl">
				<ConnectWalletStepHeading step="authorize" isLoading={!walletProvider && !user} isSuccess={!!walletProvider} />
				<ConnectWalletStepHeading step="sign" isLoading={walletProvider && !user} isSuccess={!!user} />
			</div>
			<div className="mt-8 self-end">
				<Retry onClick={retry} />
			</div>
		</div>
	)
}

export default Metamask
