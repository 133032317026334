import { createContext, useContext } from 'react'
import { RewardApplication } from '../../interfaces/interfaces'

export interface IUIContext {
	isRewardResponseModalVisible: boolean
	setIsRewardResponseModalVisible?: (val: boolean) => void
	activeRewardApplication?: RewardApplication
	setActiveRewardApplication?: (val: RewardApplication) => void
}

const UIContext = createContext<IUIContext>({
	isRewardResponseModalVisible: false,
})

export default UIContext

export const useUIContext = (): IUIContext => {
	return useContext(UIContext)
}
