import { getAddress } from '@ethersproject/address'
import { formatDuration } from 'date-fns'

export function shortenAddress(address: string, chars = 0, endingChars = 0): string {
	const parsed = isAddress(address)
	if (!parsed) {
		throw Error(`Invalid 'address' parameter '${address}'.`)
	}
	return `${parsed.substring(0, chars + 5)}...${endingChars ? `${parsed.substring(42 - endingChars)}` : ``}`
}

export function shortenAddressWithNoDots(address: string, chars = 0): string {
	const parsed = isAddress(address)
	if (!parsed) {
		// throw Error(`Invalid 'address' parameter '${address}'.`)
		return ''
	}
	return `${parsed.substring(0, chars + 5)}`
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
	try {
		return getAddress(value)
	} catch {
		return false
	}
}

export const generateRandomColor = (): string => {
	const characters = ['a', 'b', 'c', 'd', 'e', 'f', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
	const randomColorArray = []

	for (let i = 0; i < 6; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length)
		randomColorArray.push(characters[randomIndex])
	}
	return `#${randomColorArray.join('')}`
}

export function removeItemFromArray<T>(arr: Array<T>, index: number): Array<T> {
	if (index > -1) {
		arr.splice(index, 1)
	}
	return arr
}

/**
 *
 * @param incomingDate
 * @returns Number of days, hours , minutes remaining to reach the incomingDate
 */

export const getRemainingTimeFromDate = (incomingDate: number): { days: number; hours: number; minutes: number } => {
	const presentDate = new Date().getTime()
	const gap = incomingDate - presentDate

	const days = Math.floor(gap / (1000 * 60 * 60 * 24))
	const hours = Math.floor((gap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
	const minutes = Math.floor((gap % (1000 * 60)) / 1000)

	return { days, hours, minutes }
}

/**
 *
 * @param period as months
 * @returns Formats the period as X Years Y Months
 */
export const formatMonthsDuration = (period: number): string => {
	if (!period) return null

	return formatDuration({
		years: Math.floor(period / 12),
		months: period % 12,
	})
}

export function shortenTxHash(str: string, chars = 0, endingChars = 0): string {
	if (!str) return null
	return `${str.substring(0, chars + 5)}...${endingChars ? `${str.substring(66 - endingChars)}` : ``}`
}
