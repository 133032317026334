import { Button } from '@components/core'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { FC, useRef, useState } from 'react'
import * as yup from 'yup'
import { getUsername } from '../../helpers/utils'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { RewardApplicationStatusEnum, RewardResponse } from '../../interfaces/interfaces'
import { shortenAddressWithNoDots } from '../../utils'
import { useUIContext } from '../contexts/UIContext'
import { FormField } from '../Forms/Common'
import { GradientButton } from '../styled/Button'
import { createToast } from '../Toasts'

const backdrop = {
	hidden: {
		opacity: 0,
		transition: {
			duration: 0.5,
		},
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.5,
			delayChildren: 0.1,
		},
	},
}

const dropVariant = {
	hidden: {
		opacity: 0,
		transition: {
			duration: 0.5,
		},
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.5,
		},
	},
}

const formSchema = yup.object({
	message: yup.string().required('Message cannot be Empty'),
})

interface Props {
	isOpen: boolean
	setIsOpen: (state: boolean) => void
}

const RewardApplyResponseModal: FC<Props> = ({ isOpen, setIsOpen }) => {
	const ref = useRef<HTMLDivElement>(null)
	useOnClickOutside(ref, () => setIsOpen(false))
	const uiContext = useUIContext()
	const [isDeclining, setIsDeclining] = useState(false)
	const [isAccepting, setIsAccepting] = useState(false)
	useLockBodyScroll(isOpen)

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					variants={backdrop}
					initial="hidden"
					animate="visible"
					exit="hidden"
					key="Modal"
					className="fixed grid place-items-center z-20 w-full h-full"
					style={{
						backgroundColor: 'rgba(2, 2, 2, 0.5)',
					}}
				>
					{uiContext.activeRewardApplication && (
						<motion.div
							ref={ref}
							variants={dropVariant}
							className="w-full sm:w-96 bg-light-1 dark:bg-darkCard rounded-2xl flex flex-col items-center overflow-hidden py-6 px-8"
						>
							<h1 className="w-full  font-semibold text-base capitalize text-center dark:text-dark-8">
								Respond to Quest Submission
							</h1>
							<div className="flex flex-col items-center mt-2 bg-whiteText dark:bg-dark-2 rounded-xl w-full p-4">
								<p className=" font-medium text-xs text-deepgray dark:text-dark-6 capitalize mt-1 flex items-center">
									Message from{' '}
									<div className="mr-2 w-5 h-5 relative rounded-full overflow-hidden col-span-2 ml-2">
										{uiContext.activeRewardApplication?.user.profile_pic_url ? (
											<Image
												src={uiContext.activeRewardApplication?.user.profile_pic_url}
												layout="fill"
												objectFit="contain"
											/>
										) : (
											<div
												className="h-5 w-5 mr-4 rounded-full overflow-hidden"
												style={{
													background: `linear-gradient(${uiContext.activeRewardApplication?.user.gradient_1}, ${uiContext.activeRewardApplication?.user.gradient_2})`,
												}}
											></div>
										)}
									</div>
									<Link href="/[profile]" as={`/${getUsername(uiContext.activeRewardApplication.user, true)}`}>
										<a onClick={() => setIsOpen(false)} className="flex items-center">
											{uiContext.activeRewardApplication?.user.user_name ? (
												getUsername(uiContext.activeRewardApplication?.user)
											) : (
												<span className="rounded-full dark:text-dark-8 text-[10px] bg-[#F2F2F2] dark:bg-dark-3 text-[#bdbdbd] px-2 py-1 font-medium">
													{shortenAddressWithNoDots(uiContext.activeRewardApplication?.user.wallet_address)}
												</span>
											)}
											{uiContext.activeRewardApplication?.user?.is_verified && (
												<div className="relative w-3 h-3 mx-1">
													<Image src="/images/new-verify.svg" layout="fill" objectFit="contain" />
												</div>
											)}
										</a>
									</Link>{' '}
								</p>
								<p className="font-medium text-sm text-deepgray dark:text-dark-8 mt-2 w-full break-words">
									{uiContext.activeRewardApplication?.message}
								</p>
							</div>
							<Formik
								initialValues={{ message: '' }}
								validationSchema={formSchema}
								onSubmit={(values) => {
									console.log(values)
									setIsOpen(false)
								}}
							>
								{({ values, handleReset }) => {
									const onAccept = async (): Promise<void> => {
										setIsAccepting(true)
										const body: RewardResponse = {
											application_id: uiContext.activeRewardApplication.id,
											message: values.message,
											status: RewardApplicationStatusEnum.ACCEPTED,
										}

										try {
											await axios.post('/api/reward/response', body, { withCredentials: true })
											createToast({ body: 'Accepted application' })
										} catch (error) {
											console.warn(error?.response?.data)
											createToast({ body: error?.response?.data?.message ?? 'Failed to accept application' })
										} finally {
											setIsAccepting(false)
											handleReset()
											setIsOpen(false)
										}
									}
									const onDecline = async (): Promise<void> => {
										setIsDeclining(true)
										const body: RewardResponse = {
											application_id: uiContext.activeRewardApplication.id,
											message: values.message,
											status: RewardApplicationStatusEnum.DECLINED,
										}
										try {
											await axios.post('/api/reward/response', body, { withCredentials: true })
											createToast({ body: 'Application declined' })
										} catch (error) {
											console.warn(error?.response?.data)
											createToast({ body: error?.response?.data?.message ?? 'Failed to decline application' })
										} finally {
											setIsDeclining(false)
											handleReset()
											setIsOpen(false)
										}
									}
									return (
										<Form className="w-full">
											<FormField
												title="Your Reply"
												component="textarea"
												name="message"
												type="message"
												className="flex flex-col items-center"
											/>
											<div className="w-full flex-col justify-between items-center">
												{uiContext.activeRewardApplication?.status === RewardApplicationStatusEnum.ACCEPTED ||
												uiContext.activeRewardApplication?.status === RewardApplicationStatusEnum.DECLINED ? (
													<Button className="mt-4" disabled>
														{uiContext.activeRewardApplication?.status === RewardApplicationStatusEnum.ACCEPTED
															? 'Accepted'
															: 'Declined'}
													</Button>
												) : (
													<>
														<Button onClick={onAccept} className="mt-4" isLoading={isAccepting} disabled={isDeclining}>
															Accept
														</Button>
														<GradientButton
															onClick={onDecline}
															className="card-bg py-3 mt-4 dark:border-dark-3 border-2 text-deepgray dark:text-dark-8"
															loading={isDeclining}
															disabled={isAccepting}
															gradientOff={true}
														>
															Reject
														</GradientButton>
													</>
												)}
											</div>
										</Form>
									)
								}}
							</Formik>
						</motion.div>
					)}
				</motion.div>
			)}
		</AnimatePresence>
	)

	// return (
	// 	<Modal
	// 		title="Respond to reward application"
	// 		visible={uiContext.isRewardResponseModalVisible}
	// 		onCancel={() => uiContext.setIsRewardResponseModalVisible(false)}
	// 		footer={[
	// 			<Button
	// 				key="decline"
	// 				bg="#ff0001"
	// 				bgHover="#ff4142"
	// 				className="ml-2 gt-flexa"
	// 				onClick={onDecline}
	// 				loading={isDeclining}
	// 				disabled={isAccepting}
	// 			>
	// 				Decline
	// 			</Button>,
	// 			<Button
	// 				key="accept"
	// 				bg="#22dd34"
	// 				bgHover="#53e661"
	// 				className="ml-2 gt-flexa"
	// 				onClick={onAccept}
	// 				loading={isAccepting}
	// 				disabled={isDeclining}
	// 			>
	// 				Accept
	// 			</Button>,
	// 		]}
	// 	>
	// 		{uiContext.activeRewardApplication ? (
	// 			<>
	// 				<div className="">
	// 					<h3 className="gt-flexa text-xl">
	// 						Message from{' '}
	// 						<Link href="/[profile]" as={`/${getUsername(uiContext.activeRewardApplication.user, true)}`}>
	// 							<a>{getUsername(uiContext.activeRewardApplication?.user)}:</a>
	// 						</Link>
	// 					</h3>
	// 					<p>{uiContext.activeRewardApplication?.message}</p>
	// 				</div>
	// 				<Form layout="vertical" form={form} className="mt-4">
	// 					<Form.Item label="Reply" name="message">
	// 						<Input.TextArea className="py-2" style={{ minHeight: '8rem' }} />
	// 					</Form.Item>
	// 				</Form>
	// 			</>
	// 		) : null}
	// 	</Modal>
	// )
}

export default RewardApplyResponseModal
